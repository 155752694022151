import { Chip, Stack, Typography } from "@mui/material";
import React from "react";
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import HeightIcon from '@mui/icons-material/Height';
import SpeedIcon from '@mui/icons-material/Speed';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import FlightIcon from '@mui/icons-material/Flight';
import WifiIcon from '@mui/icons-material/Wifi';

export interface MetricsProps {
  style?: React.CSSProperties;
}

function Metrics(props: MetricsProps) {
  const iconStyle = {
    color: 'white',
  };
  const chipStyle = {
    margin: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
  };
  const stateChipStyle = {
    margin: 2,
    backgroundColor: '#fbc02d',
    color: 'white',
  };

  const style = props.style ? Object.assign({}, props.style) : {
    margin: 8,
    marginLeft: 65,
  };

  return <React.Fragment>
    <div style={style}>
      <Chip icon={<SpeedIcon style={iconStyle} />} label="18m/s" style={chipStyle} />
      <Chip icon={<HeightIcon style={iconStyle} />} label="14m" style={chipStyle} />
      <Chip icon={<SocialDistanceIcon style={iconStyle} />} label="6m" style={chipStyle} />
      <Chip label="LANDED" style={stateChipStyle} />
      <Chip icon={<SportsEsportsIcon style={iconStyle} />} label={
        <Stack direction="row" spacing={0} style={{textAlign: 'center', verticalAlign: 'middle'}}>
          <Typography variant="body2">92%</Typography>
          <SignalCellularAltIcon style={iconStyle} />
        </Stack>
      } style={chipStyle} />
      <Chip icon={<FlightIcon style={iconStyle} />} label={
        <Stack direction="row" spacing={0} style={{textAlign: 'center', verticalAlign: 'middle'}}>
          <Typography variant="body2">98%</Typography>
          <WifiIcon style={iconStyle} />
        </Stack>
      } style={chipStyle} />
    </div>
  </React.Fragment>;
}

export default Metrics;
