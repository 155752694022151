import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import MapView from './pages/MapView';
import { APIProvider } from '@vis.gl/react-google-maps';
import { LeftMenuContext, LeftMenuContextProps } from './components/LeftMenu';
import DroneView from './pages/DroneView';
import { PipContext, PipContextProps } from './components/Pip';

function App() {
  const apiKey = 'AIzaSyD68kpit7GVfUExBXN8EwiKmBH9pk-34lA';

  const [ leftMenuOpen, setLeftMenuOpen ] = React.useState<boolean>(false);
  const leftMenuCtxValue: LeftMenuContextProps = { open: leftMenuOpen, setOpen: setLeftMenuOpen };

  const [ pipPage, setPipPage ] = React.useState<'map' | 'drone'>('drone');
  const pipCtxValue: PipContextProps = { page: pipPage, setPage: setPipPage };

  return (
    <APIProvider apiKey={apiKey}>
      <PipContext.Provider value={pipCtxValue}>
        <LeftMenuContext.Provider value={leftMenuCtxValue}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<MapView />} />
                <Route path="map" element={<MapView />} />
                <Route path="drone" element={<DroneView />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </LeftMenuContext.Provider>
      </PipContext.Provider>
    </APIProvider>
  );
}

export default App;
