import React from 'react';
import { Map, MapControl, ControlPosition } from '@vis.gl/react-google-maps';
import Metrics from '../components/Metrics';
import Pip, { PipContext } from '../components/Pip';
import { Fab } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import DroneActionsButton from '../components/DroneActionsButton';
import { useNavigate } from 'react-router-dom';
import MenuButton from '../components/MenuButton';

function MapView() {
  const pipCtx = React.useContext(PipContext);

  const [isLeft, setIsLeft] = React.useState<boolean>(true);
  const [isUp, setIsUp] = React.useState<boolean>(false);
  const [pipPosition, setPipPosition] = React.useState<(typeof ControlPosition)[keyof typeof ControlPosition]>(ControlPosition.LEFT_BOTTOM);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLeft && isUp) {
      setPipPosition(ControlPosition.LEFT_TOP);
    } else if (isLeft && !isUp) {
      setPipPosition(ControlPosition.LEFT_BOTTOM);
    } else if (!isLeft && isUp) {
      setPipPosition(ControlPosition.RIGHT_TOP);
    } else {
      setPipPosition(ControlPosition.RIGHT_BOTTOM);
    }
  }, [isLeft, isUp]);

  return (
    <Map
      zoom={3}
      center={{lat: 22.54992, lng: 0}}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <MapControl position={ControlPosition.TOP_LEFT}>
        <MenuButton />
      </MapControl>
      <MapControl position={ControlPosition.TOP_CENTER}>
        <Metrics />
      </MapControl>
      <MapControl position={ControlPosition.RIGHT_BOTTOM}>
        <Fab style={{
          margin: 8,
        }}>
          <MyLocationIcon />
        </Fab>
        <DroneActionsButton />
      </MapControl>
      <MapControl position={pipPosition}>
        <Pip
          onSwipedLeft={() => {
            setIsLeft(true);
          }}
          onSwipedRight={() => {
            setIsLeft(false);
          }}
          onSwipedUp={() => {
            setIsUp(true);
          }}
          onSwipedDown={() => {
            setIsUp(false);
          }}
          onClick={() => {
            navigate('/drone');
            pipCtx?.setPage('map');
          }}
          />
      </MapControl>
    </Map>
  );
}

export default MapView;
