import { Fab } from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { LeftMenuContext } from './LeftMenu';

export interface LeftMenuContextProps {
  static?: boolean;
}

function MenuButton(props: LeftMenuContextProps) {
  const leftMenuCtx = React.useContext(LeftMenuContext);

  const style: React.CSSProperties = props.static ? {
    position: 'absolute',
    top: 8,
    left: 8,
  } : {
    margin: 8,
  };

  return (
    <Fab
      onClick={() => {leftMenuCtx?.setOpen(true)}}
      style={style}>
      <MenuIcon />
    </Fab>
  );
}

export default MenuButton;
