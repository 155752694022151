import { Map } from '@vis.gl/react-google-maps';
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import useSwipe from "../hooks/useSwipe";
import { useLocation } from 'react-router-dom';

export interface PipContextProps {
  page: 'map' | 'drone';
  setPage: (page: 'map' | 'drone') => void;
}

export interface PipProps {
  onSwipedLeft?: () => void
  onSwipedRight?: () => void
  onSwipedUp?: () => void
  onSwipedDown?: () => void
  onClick?: () => void
  style?: React.CSSProperties
}

export const PipContext = React.createContext<PipContextProps | null>(null);

function Pip(props: PipProps) {
  const pipCtx = React.useContext(PipContext);

  const swipeHandlers = useSwipe(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === '/' || location.pathname.startsWith('/map')) {
      pipCtx?.setPage('drone');
    } else if (location.pathname.startsWith('/drone')) {
      pipCtx?.setPage('map');
    }
  }, [location]);

  const style = props.style ? Object.assign({}, props.style) : {};
  style.margin = 8;
  style.width = matches ? 320 : 240;
  style.height = matches ? 240 : 185;
  style.backgroundColor = 'white';
  style.overflow = 'hidden';

  return <div
    onTouchStart={swipeHandlers.onTouchStart}
    onTouchEnd={swipeHandlers.onTouchEnd}
    onTouchMove={swipeHandlers.onTouchMove}
    onMouseDown={swipeHandlers.onTouchStart}
    onMouseUp={swipeHandlers.onTouchEnd}
    onMouseMove={swipeHandlers.onTouchMove}
    onClick={props.onClick}
    >
    <Paper elevation={3} style={style}>
      { pipCtx?.page === 'drone' && <img src="/imgs/aerial.jpg" style={{
        width: '100%',
        height: '100%',
      }} /> }
      { pipCtx?.page === 'map' && <Map
        zoom={3}
        center={{lat: 22.54992, lng: 0}}
        gestureHandling={'none'}
        disableDefaultUI={true}
        style={{
          width: '100%',
          height: '100%',
        }}
      /> }
    </Paper>
  </div>;
}

export default Pip;
