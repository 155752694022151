import { Backdrop, Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import React from "react";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HomeIcon from '@mui/icons-material/Home';

const actions = [
  { icon: <FlightTakeoffIcon />, name: 'Start Flightplan' },
  { icon: <HomeIcon />, name: 'Home' },
];

export interface DroneActionsButtonProps {
  style?: React.CSSProperties;
}

function DroneActionsButton(props: DroneActionsButtonProps) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = props.style ? Object.assign({}, props.style) : {};
  style.transform = 'translateZ(0px)';
  style.flexGrow = 1;

  return (
    <Box sx={style}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="Drone Actions"
        sx={{ position: 'absolute', bottom: 10, right: 80 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}

export default DroneActionsButton;
