import React from "react";

interface SwipeInput {
  onSwipedLeft?: () => void
  onSwipedRight?: () => void
  onSwipedUp?: () => void
  onSwipedDown?: () => void
}

interface SwipeOutput {
  onTouchStart: (e: React.TouchEvent | React.MouseEvent) => void
  onTouchMove: (e: React.TouchEvent | React.MouseEvent) => void
  onTouchEnd: () => void
}

export default (input: SwipeInput): SwipeOutput => {
  const [touchStartX, setTouchStartX] = React.useState(0);
  const [touchEndX, setTouchEndX] = React.useState(0);
  const [touchStartY, setTouchStartY] = React.useState(0);
  const [touchEndY, setTouchEndY] = React.useState(0);

  const minSwipeDistance = 50;

  const onTouchStart = (e: React.TouchEvent | React.MouseEvent) => {
    setTouchEndX(0); // otherwise the swipe is fired even with usual touch events
    setTouchEndY(0); // otherwise the swipe is fired even with usual touch events

    const mouseEvent = e as React.MouseEvent;
    if (mouseEvent.pageX && mouseEvent.pageY) {
       // if is a mouse event
      setTouchStartX(mouseEvent.pageX);
      setTouchStartY(mouseEvent.pageY);
    }

    const touchEvent = e as React.TouchEvent;
    if (touchEvent.targetTouches && touchEvent.targetTouches.length > 0) {
      // if is a touch event
      setTouchStartX(touchEvent.targetTouches[0].clientX);
      setTouchStartY(touchEvent.targetTouches[0].clientY);
    }
  }

  const onTouchMove = (e: React.TouchEvent | React.MouseEvent) => {
    const mouseEvent = e as React.MouseEvent;
    if (mouseEvent.pageX && mouseEvent.pageY) {
      // if is a mouse event
      setTouchEndX(mouseEvent.pageX);
      setTouchEndY(mouseEvent.pageY);
    }

    const touchEvent = e as React.TouchEvent;
    if (touchEvent.targetTouches && touchEvent.targetTouches.length > 0) {
      // if is a touch event
      setTouchEndX(touchEvent.targetTouches[0].clientX);
      setTouchEndY(touchEvent.targetTouches[0].clientY);
    }
  }

  const onTouchEnd = () => {
    if (!touchStartX || !touchEndX || !touchStartY || !touchEndY) return;
    const distanceX = touchStartX - touchEndX;
    const isLeftSwipe = distanceX > minSwipeDistance;
    const isRightSwipe = distanceX < -minSwipeDistance;
    if (isLeftSwipe && input.onSwipedLeft) {
      input.onSwipedLeft();
    }
    if (isRightSwipe && input.onSwipedRight) {
      input.onSwipedRight();
    }
    const distanceY = touchStartY - touchEndY;
    const isUpSwipe = distanceY > minSwipeDistance;
    const isDownSwipe = distanceY < -minSwipeDistance;
    if (isUpSwipe && input.onSwipedUp) {
      input.onSwipedUp();
    }
    if (isDownSwipe && input.onSwipedDown) {
      input.onSwipedDown();
    }
  }

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd
  }
}
