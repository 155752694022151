import { Chip, Collapse, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar } from "@mui/material";
import React from "react";
import FlightIcon from '@mui/icons-material/Flight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DashboardIcon from '@mui/icons-material/Dashboard';

export interface LeftMenuContextProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LeftMenuContext = React.createContext<LeftMenuContextProps | null>(null);

function LeftMenu() {
  const leftMenuCtx = React.useContext(LeftMenuContext);

  const [dronesOpen, setDronesOpen] = React.useState(true);

  const handleDronesClick = () => {
    setDronesOpen(!dronesOpen);
  };

  return (
    <Drawer
      anchor="left"
      open={leftMenuCtx?.open}
      onClose={() => {leftMenuCtx?.setOpen(false)}}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="close menu"
          onClick={() => {leftMenuCtx?.setOpen(false)}}
          sx={{ mr: 2 }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </Toolbar>
      <List
        sx={{ width: '100%', minWidth: 270, bgcolor: 'background.paper' }}
        component="nav"
      >
        <ListItemButton onClick={handleDronesClick} alignItems="flex-start">
          <ListItemIcon>
            <FlightIcon />
          </ListItemIcon>
          <ListItemText primary="Fleet" />
          {dronesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={dronesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} alignItems="flex-start">
              <ListItemText primary="Drone 1" />
              <Chip label="LANDED" style={{backgroundColor: '#fbc02d', color: 'white'}} />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} alignItems="flex-start">
              <ListItemText primary="Drone 2" />
              <Chip label="UNKNOWN" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton href="https://stream.dromt.it" target="_blank" alignItems="flex-start">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </List>
    </Drawer>
  );
}

export default LeftMenu;
